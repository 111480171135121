import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Row, Col } from "react-bootstrap"
import { FaArrowLeft } from "react-icons/fa"
import { Link } from "gatsby"

const AvisoLegal = () => {
  return (
    <Layout navbar={false} pageInfo={{ pageName: "index" }}>
      <SEO description="" title="Aviso Legal" />
      <section className="product--header">
        <Row>
          <Col md={{ span: 5, offset: 1 }} className="justify-content-center">
            <Link to="/">
              <FaArrowLeft />
            </Link>
          </Col>
        </Row>
      </section>
      <section className="product--info">
        <Row>
          <Col md={{ span: 6, offset: 3 }} className="">
            <h2>Aviso legal y Condiciones de uso</h2>
            <p>
              De acuerdo con la Ley 34/2002 se informa que el dominio
              www.abarconesgonzalez.com es propiedad de: ABARCONES GONZÁLEZ Y
              DISTRIBUCIONES 2016, S.L. NIF: B66705880 Domicilio social: Pol.
              Ind. Santiga C/ Llobateres nº20 Talleres 6 nave 23, Barberà del
              Vallès Teléfono: +34 93 729 90 17 Correo electrónico:
              info@abarconesgonzalez.com
            </p>
            <h4>Propiedad intelectual e industrial:</h4>
            <p>
              El usuario reconoce y acepta que todos los derechos de propiedad
              industrial e intelectual sobre los contenidos y / o cualesquiera
              otros elementos insertados por ABARCONES GONZÁLEZ Y DISTRIBUCIONES
              2016, S.L. en esta página web pertenecen a ABARCONES GONZÁLEZ Y
              DISTRIBUCIONES 2016, S.L. , que el usuario de este sitio web debe
              respetar. En ningún caso el acceso a esta página web implica
              ningún tipo de permiso, renuncia, transmisión, licencia o cesión
              total ni parcial de dichos derechos por parte de su titular, salvo
              que éste establezca expresamente lo contrario. Queda totalmente
              prohibida la utilización total o parcial de ningún elemento o
              material incluido en esta página web, así como su total o parcial
              reproducción, comunicación y / o distribución sin autorización
              expresa de ABARCONES GONZÁLEZ Y DISTRIBUCIONES 2016, S.L., ni su
              modificación, alteración, descompilación y / o cualquier otro acto
              de explotación del sitio web.
            </p>
            <h4>Acceso y uso del sitio web:</h4>
            <p>
              Tanto el acceso al sitio web como el uso no consentido que pueda
              hacerse de la información contenida en la página es exclusivamente
              responsabilidad de quien lo realiza. El usuario se compromete a
              utilizar los contenidos, información y datos del sitio web de
              conformidad con las condiciones, términos y políticas vigentes,
              con la normativa de aplicación y con las buenas costumbres
              generalmente aceptadas y el orden público. El usuario se abstendrá
              de utilizar los contenidos del sitio web con fines o efectos
              ilícitos, prohibidos o contrarios a los aquí establecidos, lesivos
              de los derechos e intereses de ABARCONES GONZÁLEZ Y DISTRIBUCIONES
              2016, S.L., del resto de usuarios, de terceros o que de cualquier
              forma puedan dañar, inutilizar, sobrecargar o deteriorar este
              sitio web o impedir la normal utilización o el hecho de disfrutar
              de ellas por parte de los usuarios. ABARCONES GONZÁLEZ Y
              DISTRIBUCIONES 2016, S.L. no responderá de ninguna consecuencia,
              daño o perjuicio que pudiera derivarse de dicho acceso o uso o del
              incumplimiento de las presentes condiciones, términos y políticas
              ni se hará responsable de los errores de seguridad que se puedan
              producir ni de los desperfectos que puedan causarse al sistema
              informático del usuario (hardware y software) o los ficheros o
              documentos almacenados en el mismo como consecuencia de: (i) la
              presencia de un virus en el ordenador del usuario que sea
              utilizado para la conexión a los servicios y / o productos
              ofrecidos por ABARCONES GONZÁLEZ Y DISTRIBUCIONES 2016, S.L. a
              través de su sitio web; (ii) un mal funcionamiento del navegador;
              (iii) el uso de versiones no actualizadas del sistema.
            </p>
            <h4>Enlaces de terceros:</h4>
            <p>
              En caso de que ABARCONES GONZÁLEZ Y DISTRIBUCIONES 2016, S.L.
              incluya en su sitio web enlaces a páginas de sus colaboradores,
              únicamente con la intención de hacer difusión de estos. ABARCONES
              GONZÁLEZ Y DISTRIBUCIONES 2016, S.L. no se hace responsable ni de
              los contenidos ni de las informaciones comerciales incluidas en
              estos sitios o de cualquiera de los servicios o prácticas que se
              vinculen o relacionen con las páginas web enlazadas, ni de las
              medidas de seguridad adoptadas por cualquier otra página web a la
              que se tenga acceso desde este sitio web, páginas a las que el
              interesado accede bajo su única responsabilidad. Del mismo modo,
              tampoco se garantiza la ausencia de virus u otros elementos en los
              contenidos enlazados desde el presente sitio web que puedan
              producir alteraciones en el sistema informático (hardware y
              software) y / o en los documentos o los ficheros del usuario,
              eximiendo igualmente a ABARCONES GONZÁLEZ Y DISTRIBUCIONES 2016,
              S.L. de toda responsabilidad derivada de los daños de cualquier
              tipo ocasionados por todo lo descrito anteriormente.
            </p>
            <h4>Modificación de los términos y condiciones de uso:</h4>
            <p>
              ABARCONES GONZÁLEZ Y DISTRIBUCIONES 2016, S.L. se reserva el
              derecho a modificar en todo momento y sin previo aviso las
              presentes condiciones, términos y políticas de privacidad para
              adaptarlas a las novedades legislativas o jurisprudenciales así
              como a las modificaciones o prácticas de la industria, con la
              obligación, por parte del usuario de consultar periódicamente
              estas condiciones, términos y políticas con el fin de comprobar o
              asegurarse de la existencia de cambios, tomando como referencia la
              fecha de la última actualización.
            </p>
          </Col>
        </Row>
      </section>
    </Layout>
  )
}

export default AvisoLegal
