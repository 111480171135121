import React from "react"

import { Product } from "../../components/sections"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Row, Col } from "react-bootstrap"

const AbarconesTriangularesComponent = ({ data }) => {
  const abarconesTriangularesImage =
    data.abarconesTriangularesImage.childImageSharp.fluid
  const abarconesTriangulares2Image =
    data.abarconesTriangulares2Image.childImageSharp.fluid
  const abarconesTriangulares3Image =
    data.abarconesTriangulares3Image.childImageSharp.fluid
  const abarconesTriangulares4Image =
    data.abarconesTriangulares4Image.childImageSharp.fluid

  return (
    <Product
      title="Abarcones Triangulares"
      name="Abarcones Triangulares"
      description="Abarcones para la fijación de tubos con formas triangulares de acero al carbono o acero inoxidable en soportes y perfiles. Puede equiparse con las arandelas, tuercas y pletinas correspondientes."
      image={abarconesTriangularesImage}
      metaDescription="Abarcones triangulares para la fijación de tubos en soportes y perfiles. Fabricados en Acero al Carbono o Acero Inoxidable. 100% a medida. Calidad certificada."
      gallery={
        <Row id="gallery" className="gallery">
          <Col md={4} className="square">
            <Img fluid={abarconesTriangulares2Image} alt={""} />
          </Col>
          <Col md={4} className="square">
            <Img fluid={abarconesTriangulares3Image} alt={""} />
          </Col>
          <Col md={4} className="square">
            <Img fluid={abarconesTriangulares4Image} alt={""} />
          </Col>
        </Row>
      }
    />
  )
}

const AbarconesTriangulares = props => (
  <StaticQuery
    query={graphql`
      query {
        abarconesTriangularesImage: file(
          relativePath: { eq: "products/abarcones_triangulares.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesTriangulares2Image: file(
          relativePath: { eq: "products/abarcones_triangulares_2.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesTriangulares3Image: file(
          relativePath: { eq: "products/abarcones_triangulares_3.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesTriangulares4Image: file(
          relativePath: { eq: "products/abarcones_triangulares_4.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <AbarconesTriangularesComponent data={data} {...props} />}
  />
)

export default AbarconesTriangulares
