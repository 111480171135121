import React from "react"

import { Product } from "../../components/sections"
import { StaticQuery, graphql } from "gatsby"

import { Row, Col } from "react-bootstrap"
import Img from "gatsby-image"

const AbarconesCuadradosComponent = ({ data }) => {
  const abarconesCuadradosImage =
    data.abarconesCuadradosImage.childImageSharp.fluid
  const abarconesCuadrados2Image =
    data.abarconesCuadrados2Image.childImageSharp.fluid
  const abarconesCuadrados3Image =
    data.abarconesCuadrados3Image.childImageSharp.fluid
  const abarconesCuadrados4Image =
    data.abarconesCuadrados4Image.childImageSharp.fluid

  return (
    <Product
      title="Abarcones Cuadrados"
      name="Abarcones Cuadrados"
      description="Abarcones para la fijación de tubos cuadrados de acero al carbono o acero inoxidable en soportes y perfiles. Puede equiparse con las arandelas, tuercas y pletinas correspondientes."
      image={abarconesCuadradosImage}
      metaDescription="Abarcones cuadrados para la fijación de tubos en soportes y perfiles. Fabricados en Acero al Carbono o Acero Inoxidable. 100% a medida. Calidad certificada."
      gallery={
        <Row id="gallery" className="gallery">
          <Col md={4} className="square">
            <Img fluid={abarconesCuadrados2Image} alt={""} />
          </Col>
          <Col md={4} className="square">
            <Img fluid={abarconesCuadrados3Image} alt={""} />
          </Col>
          <Col md={4} className="square">
            <Img fluid={abarconesCuadrados4Image} alt={""} />
          </Col>
        </Row>
      }
    />
  )
}

const AbarconesCuadrados = props => (
  <StaticQuery
    query={graphql`
      query {
        abarconesCuadradosImage: file(
          relativePath: { eq: "products/abarcones_cuadrados.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesCuadrados2Image: file(
          relativePath: { eq: "products/abarcones_cuadrados_2.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesCuadrados3Image: file(
          relativePath: { eq: "products/abarcones_cuadrados_3.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesCuadrados4Image: file(
          relativePath: { eq: "products/abarcones_cuadrados_4.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <AbarconesCuadradosComponent data={data} {...props} />}
  />
)

export default AbarconesCuadrados
