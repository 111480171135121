import React from "react"

import { Product } from "../../components/sections"
import { StaticQuery, graphql } from "gatsby"

const AbarconesSobredimensionadosComponent = ({ data }) => {
  const abarconesSobredimensionadosImage =
    data.abarconesSobredimensionadosImage.childImageSharp.fluid

  return (
    <Product
      title="Abarcones Sobredimensionados"
      name="Abarcones Sobredimensionados"
      description="Abarcones a medida para la fijación de tubos de grandes dimensiones, adaptables a cualquier formato. Puede equiparse con las arandelas, tuercas y pletinas correspondientes."
      image={abarconesSobredimensionadosImage}
      metaDescription="Abarcones de grandes dimensiones para la fijación de tubos en cualquier formato. Fabricados en Acero al Carbono o Acero Inoxidable. 100% a medida. Calidad certificada."
    />
  )
}

const AbarconesSobredimensionados = props => (
  <StaticQuery
    query={graphql`
      query {
        abarconesSobredimensionadosImage: file(
          relativePath: { eq: "products/abarcones_sobredimensionados_2.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <AbarconesSobredimensionadosComponent data={data} {...props} />
    )}
  />
)

export default AbarconesSobredimensionados
