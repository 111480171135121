import React from "react"

import { Product } from "../../components/sections"
import { StaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import { Row, Col } from "react-bootstrap"

const PletinasYTensoresComponent = ({ data }) => {
  const pletinasYTensoresImage =
    data.pletinasYTensoresImage.childImageSharp.fluid
  const pletinasYTensores2Image =
    data.pletinasYTensores2Image.childImageSharp.fluid
  const pletinasYTensores3Image =
    data.pletinasYTensores3Image.childImageSharp.fluid
  const pletinasYTensores4Image =
    data.pletinasYTensores4Image.childImageSharp.fluid

  return (
    <Product
      title="Pletinas y Tensores"
      name="Pletinas y Tensores"
      description="Pletinas y Tensores fabricados a medida en diferentes formatos y espesores adaptadas a las necesidades y especificaciones de cada proyecto. Ofrecemos soluciones totalmente personalizadas. "
      image={pletinasYTensoresImage}
      metaDescription="Pletinas y Tensores a medida en diferentes formatos y espesores. En Acero Inoxidable o Acero al Carbono. Soluciones 100% personalizadas. Calidad certificada."
      gallery={
        <Row id="gallery" className="gallery">
          <Col md={4} className="square">
            <Img fluid={pletinasYTensores2Image} alt={""} />
          </Col>
          <Col md={4} className="square">
            <Img fluid={pletinasYTensores3Image} alt={""} />
          </Col>
          <Col md={4} className="square">
            <Img fluid={pletinasYTensores4Image} alt={""} />
          </Col>
        </Row>
      }
    />
  )
}

const PletinasYTensores = props => (
  <StaticQuery
    query={graphql`
      query {
        pletinasYTensoresImage: file(
          relativePath: { eq: "products/pletinas_y_tensores.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pletinasYTensores2Image: file(
          relativePath: { eq: "products/pletinas_y_tensores_2.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pletinasYTensores3Image: file(
          relativePath: { eq: "products/pletinas_y_tensores_3.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pletinasYTensores4Image: file(
          relativePath: { eq: "products/pletinas_y_tensores_4.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <PletinasYTensoresComponent data={data} {...props} />}
  />
)

export default PletinasYTensores
