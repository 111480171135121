import React from "react"
import { Row, Col, Form, Button } from "react-bootstrap"

const Contact = () => {
  return (
    <>
      <section className="contacto" id="contacto">
        <Row>
          <Col
            md={{ span: 5, offset: 1 }}
            className="justify-content-center"
            data-sal="slide-right"
            data-sal-delay="400"
            data-sal-easing="ease"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2987.0578616973135!2d2.1417459154300666!3d41.52468727925181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a49589f4719ca1%3A0xea4ceb4a4729764b!2sAbarcones%20Gonz%C3%A1lez!5e0!3m2!1ses!2ses!4v1586558480262!5m2!1ses!2ses"
              width="100%"
              height="470"
              frameBorder="0"
              scrolling="no"
              allowFullScreen=""
              aria-hidden="false"
              marginHeight="0"
              marginWidth="0"
              tabIndex="0"
            ></iframe>
          </Col>
          <Col
            md={{ span: 4, offset: 1 }}
            data-sal="zoom-in"
            data-sal-delay="300"
            data-sal-easing="ease"
            className="justify-content-center info"
          >
            <h1>Contáctanos</h1>
            <p>
              <strong>ABARCONES GONZÁLEZ Y DISTRIBUCIONES 2016, S.L.</strong>
            </p>
            <p>
              Pol. Ind. Santiga C/ Llobateres nº20 <br />
              Talleres 6 nave 23, Barberà del Vallès
            </p>
            <p>Tel. 93 729 90 17</p>
            <p>
              <a
                title="Enviar email a info@abarconesgonzalez.com"
                href="mailto:info@abarconesgonzalez.com"
              >
                info@abarconesgonzalez.com
              </a>
            </p>
          </Col>
        </Row>
      </section>
      <section className="contacto">
        <Row
          className="title__form"
          data-sal="slide-down"
          data-sal-delay="500"
          data-sal-easing="ease"
        >
          <Col className="text-center" xs={12} md={{ span: 6, offset: 3 }}>
            <h2>Déjanos tu consulta</h2>
            <p>
              Déjanos tus datos y tu consulta. <br />
              Nos pondremos en contacto contigo muy pronto.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="form text-center" md={{ span: 6, offset: 3 }}>
            {/*
            <Form
              action="https://getform.io/f/46bc70c9-64c3-4409-a5d9-315e1de69de8"
              name="contact"
              method="post"
            >
              <Form.Group controlId="formName">
                <Form.Control
                  type="text"
                  name="nombre"
                  placeholder="Nombre *"
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Dirección Email *"
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPhone">
                <Form.Control
                  type="text"
                  name="telefono"
                  placeholder="Teléfono"
                />
              </Form.Group>
              <Form.Group controlId="formSubject">
                <Form.Control
                  required
                  type="text"
                  name="asunto"
                  placeholder="Asunto *"
                />
              </Form.Group>
              <Form.Group controlId="formMessage">
                <Form.Control
                  required
                  as="textarea"
                  rows="3"
                  placeholder="Mensaje *"
                  name="mensaje"
                />
              </Form.Group>
              <Button className="" variant="primary" type="submit">
                Enviar
              </Button>
            </Form>
            */}
            <a
              title="Enviar email a info@abarconesgonzalez.com"
              href="mailto:info@abarconesgonzalez.com"
            >
              <Button className="" variant="primary">
                Enviar email
              </Button>
            </a>
          </Col>
        </Row>
      </section>
    </>
  )
}

export default Contact
