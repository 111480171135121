import React from "react"
import { Row, Col } from "react-bootstrap"

const Footer = () => {
  return (
    <footer>
      <Row>
        <Col md={6}>
          <p className="text-md-left text-center">
            © {new Date().getFullYear()} abarconesgonzales.com.&nbsp; &nbsp;
            <a href="/aviso-legal">Aviso Legal</a> |{" "}
            <a href="/privacidad">Privacidad</a>
          </p>
        </Col>
        <Col md={6}>
          <p className="text-md-right text-center">
            by&nbsp;
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://omind.io"
            >
              omind.io
            </a>
          </p>
        </Col>
      </Row>
    </footer>
  )
}

export default Footer
