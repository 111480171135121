import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Img from "gatsby-image"

import { Row, Col, Button } from "react-bootstrap"
import { FaArrowLeft, FaFilePdf } from "react-icons/fa"
import { Link } from "gatsby"
import { Link as LinkScroll } from "react-scroll"

import DIN3570 from "../../pdf/cotas-abarcones-din-3570.pdf"
import SERIEPESADA from "../../pdf/cotas-abarcones-serie-pesada.pdf"
import STANDARD from "../../pdf/cotas-abarcones-standard.pdf"
import MILIMETRICOS from "../../pdf/cotas-abarcones-milimetrico.pdf"
import SOBREDIMENSIONADOS from "../../pdf/cotas-abarcones-sobredimensionados.pdf"

const Product = ({
  image,
  title,
  name,
  description,
  gallery,
  metaDescription,
}) => {
  return (
    <Layout navbar={false} pageInfo={{ pageName: "index" }}>
      <SEO description={metaDescription} title={title} />
      <section className="product--header">
        <Row>
          <Col md={{ span: 5, offset: 1 }} className="justify-content-center">
            <Link to="/#productos">
              <FaArrowLeft />
            </Link>
          </Col>
        </Row>
      </section>
      <section className="product--info">
        <Row>
          <Col md={{ span: 5, offset: 1 }} className="justify-content-center">
            <h2>{name}</h2>
            <p>{description}</p>
            <ul>
              <strong>Materiales:</strong>
              <li>Acero al Carbono (F-1, F-114, F-125, etc...)</li>
              <li>
                Acero Inoxidable (Aisi 304, 316, dúplex, superduplex, etc...)
              </li>
            </ul>
            <ul>
              <strong>Acabados del acero al carbono:</strong>
              <li>Zincado</li>
              <li>Bicromatado</li>
              <li>Galvanizado en caliente</li>
              <li>Pavonado</li>
              <li>Dracomet</li>
              <li>Otros (Consúltanos)</li>
            </ul>
            <ul>
              <strong>CALIDADES:</strong>
              <li>Carbono: F-1, F-114, F-125, etc...</li>
              <li>Inoxidable: Aisi 304, 316L, Superduplex, etc...</li>
            </ul>
            <ul>
              <strong>SERIE:</strong>
              <li>
                <a rel="noopener noreferrer" href={DIN3570} target="_blank">
                  <FaFilePdf />
                  <span class="text">DIN 3570</span>
                  <span class="button">Abrir</span>
                </a>
              </li>
              <li>
                <a rel="noopener noreferrer" href={SERIEPESADA} target="_blank">
                  <FaFilePdf />
                  <span class="text">Serie Pesada</span>
                  <span class="button">Abrir</span>
                </a>
              </li>
              <li>
                <a rel="noopener noreferrer" href={STANDARD} target="_blank">
                  <FaFilePdf />
                  <span class="text">Serie Standard</span>
                  <span class="button">Abrir</span>
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  href={SOBREDIMENSIONADOS}
                  target="_blank"
                >
                  <FaFilePdf />
                  <span class="text">Sobredimensionados</span>
                  <span class="button">Abrir</span>
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  href={MILIMETRICOS}
                  target="_blank"
                >
                  <FaFilePdf />
                  <span class="text">Milimétricos</span>
                  <span class="button">Abrir</span>
                </a>
              </li>
            </ul>
          </Col>
          <Col md={{ span: 4, offset: 1 }} className="justify-content-center">
            <Img fluid={image} alt={title} />
            {gallery && (
              <div style={{ textAlign: "center" }}>
                <LinkScroll smooth={true} to="gallery">
                  <Button>Ver Galería</Button>
                </LinkScroll>
              </div>
            )}
          </Col>
        </Row>
      </section>
      <section style={{ overflow: "hidden" }}>{gallery && gallery}</section>
    </Layout>
  )
}

export default Product
