import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import { Link } from "gatsby"

const ProductsComponent = ({ data }) => {
  const abarconesRedondosImage =
    data.abarconesRedondosImage.childImageSharp.fluid
  const abarconesCuadradosImage =
    data.abarconesCuadradosImage.childImageSharp.fluid
  const abarconesTorneadosImage =
    data.abarconesTorneadosImage.childImageSharp.fluid
  const abarconesTriangularesImage =
    data.abarconesTriangularesImage.childImageSharp.fluid
  const abarconesSobredimensionadosImage =
    data.abarconesSobredimensionadosImage.childImageSharp.fluid
  const abarconesEspecialesImage =
    data.abarconesEspecialesImage.childImageSharp.fluid
  const ganchosYPernosImage = data.ganchosYPernosImage.childImageSharp.fluid
  const varillasImage = data.varillasImage.childImageSharp.fluid
  const pletinasYTensoresImage =
    data.pletinasYTensoresImage.childImageSharp.fluid

  return (
    <section id="productos">
      <Row
        className="title"
        data-sal="slide-down"
        data-sal-delay="500"
        data-sal-easing="ease"
      >
        <Col className="text-center" xs={12} md={{ span: 6, offset: 3 }}>
          <h1>Nuestros Productos</h1>
          <p>
            Disponemos de una amplia gama de productos para ofrecerte el sistema
            de sujeción industrial que necesitas. Todas ellas 100%
            personalizables.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={4} className="square">
          <Link to="/productos/abarcones-redondos">
            <Img fluid={abarconesRedondosImage} alt={"abarcones redondos"} />
            <div className="title">
              <h3>Abarcones Redondos</h3>
            </div>
          </Link>
        </Col>
        <Col md={4} className="square">
          <Link to="/productos/abarcones-cuadrados">
            <Img fluid={abarconesCuadradosImage} alt={"abarcones cuadrados"} />
            <div className="title">
              <h3>Abarcones Cuadrados</h3>
            </div>
          </Link>
        </Col>

        <Col md={4} className="square">
          <Link to="/productos/abarcones-torneados">
            <Img fluid={abarconesTorneadosImage} alt={"abarcones torneados"} />
            <div className="title">
              <h3>Abarcones Torneados</h3>
            </div>
          </Link>
        </Col>
        <Col md={4} className="square">
          <Link to="/productos/abarcones-triangulares">
            <Img
              fluid={abarconesTriangularesImage}
              alt={"abarcones triangulares"}
            />
            <div className="title">
              <h3>Abarcones Triangulares</h3>
            </div>
          </Link>
        </Col>
        <Col md={4} className="square">
          <Link to="/productos/abarcones-sobredimensionados">
            <Img
              fluid={abarconesSobredimensionadosImage}
              alt={"abarcones sobredimensionados"}
            />
            <div className="title">
              <h3>Abarcones Sobredimensionados</h3>
            </div>
          </Link>
        </Col>
        <Col md={4} className="square">
          <Link to="/productos/abarcones-especiales">
            <Img
              fluid={abarconesEspecialesImage}
              alt={"abarcones especiales"}
            />
            <div className="title">
              <h3>Abarcones Especiales</h3>
            </div>
          </Link>
        </Col>
        <Col md={4} className="square">
          <Link to="/productos/ganchos-y-pernos">
            <Img fluid={ganchosYPernosImage} alt={"ganchos y pernos"} />
            <div className="title">
              <h3>Ganchos y Pernos</h3>
            </div>
          </Link>
        </Col>
        <Col md={4} className="square">
          <Link to="/productos/varillas">
            <Img fluid={varillasImage} alt={"varillas"} />
            <div className="title">
              <h3>Varillas o Espárragos</h3>
            </div>
          </Link>
        </Col>

        <Col md={4} className="square">
          <Link to="/productos/pletinas-y-tensores">
            <Img fluid={pletinasYTensoresImage} alt={"pletinas y tensores"} />
            <div className="title">
              <h3>Pletinas y Tensores</h3>
            </div>
          </Link>
        </Col>
      </Row>
    </section>
  )
}

const Products = props => (
  <StaticQuery
    query={graphql`
      query {
        abarconesRedondosImage: file(
          relativePath: { eq: "products/abarcones_redondos.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesCuadradosImage: file(
          relativePath: { eq: "products/abarcones_cuadrados.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesTorneadosImage: file(
          relativePath: { eq: "products/abarcones_torneados.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesTriangularesImage: file(
          relativePath: { eq: "products/abarcones_triangulares.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesSobredimensionadosImage: file(
          relativePath: { eq: "products/abarcones_sobredimensionados.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesEspecialesImage: file(
          relativePath: { eq: "products/abarcones_especiales.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ganchosYPernosImage: file(
          relativePath: { eq: "products/ganchos_y_pernos.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        varillasImage: file(relativePath: { eq: "products/varillas.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pletinasYTensoresImage: file(
          relativePath: { eq: "products/pletinas_y_tensores.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <ProductsComponent data={data} {...props} />}
  />
)

export default Products
