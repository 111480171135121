import React from "react"

import { Product } from "../../components/sections"
import { StaticQuery, graphql } from "gatsby"

const VarillasComponent = ({ data }) => {
  const varillasImage = data.varillasImage.childImageSharp.fluid

  return (
    <Product
      title="Varillas o Espárragos"
      name="Varillas"
      description="Varillas o Espárragos fabricadas a medida en diferentes formatos y espesores adaptadas a las necesidades y especificaciones de cada proyecto. Ofrecemos soluciones totalmente personalizadas. Puede equiparse con las arandelas de bloqueo, tuercas y pletinas correspondientes."
      image={varillasImage}
      metaDescription="Varillas o Espárragos a medida en diferentes formatos y espesores. En Acero Inoxidable o Acero al Carbono. Soluciones 100% personalizadas. Calidad certificada."
    />
  )
}

const Varillas = props => (
  <StaticQuery
    query={graphql`
      query {
        varillasImage: file(relativePath: { eq: "products/varillas_2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <VarillasComponent data={data} {...props} />}
  />
)

export default Varillas
