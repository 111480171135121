import React from "react"
import { Row, Col } from "react-bootstrap"
import { StaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

const SectorsComponent = ({ data }) => {
  const construccionImageSources = [
    data.construccionImage.childImageSharp.fluid,
  ]

  const automocionImageSources = [data.automocionImage.childImageSharp.fluid]
  const energiaSolarImageSources = [
    data.energiaSolarImage.childImageSharp.fluid,
  ]
  const ingenieriaImageSources = [data.ingenieriaImage.childImageSharp.fluid]
  const instalacionesImageSources = [
    data.instalacionesImage.childImageSharp.fluid,
  ]
  const invernaderoImageSources = [data.invernaderoImage.childImageSharp.fluid]
  const maquinariaAgricolaImageSources = [
    data.maquinariaAgricolaImage.childImageSharp.fluid,
  ]
  const navalImageSources = [data.navalImage.childImageSharp.fluid]
  const petroquimicaImageSources = [
    data.petroquimicaImage.childImageSharp.fluid,
  ]
  const telecomunicacionesImageSources = [
    data.telecomunicacionesImage.childImageSharp.fluid,
  ]
  return (
    <section id="sectores">
      <Row
        className="title"
        data-sal="slide-down"
        data-sal-delay="500"
        data-sal-easing="ease"
      >
        <Col className="text-center" xs={12} md={{ span: 6, offset: 3 }}>
          <h1>Sectores</h1>
          <p>
            Trabajamos para un amplio abanico de sectores, pudiendo abordarlos
            de forma totalmente personalizada a las necesidades de cada cliente.
            Algunos de ellos són:
          </p>
        </Col>
      </Row>
      <div className="grid">
        <div
          data-sal="flip-up"
          data-sal-delay="100"
          data-sal-easing="ease"
          xs={6}
          md={3}
          className="grid__item"
        >
          <div className="content">
            <h4>Construcción</h4>{" "}
          </div>
          <Img
            fluid={construccionImageSources}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              zIndex: "-1",
            }}
            alt={""}
          />
        </div>
        <div
          data-sal="flip-up"
          data-sal-delay="200"
          data-sal-easing="ease"
          xs={6}
          md={3}
          className="grid__item"
        >
          <div className="content">
            <h4>Automoción</h4>{" "}
          </div>
          <Img
            fluid={automocionImageSources}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              zIndex: "-1",
            }}
            alt={""}
          />
        </div>
        <div
          data-sal="flip-up"
          data-sal-delay="300"
          data-sal-easing="ease"
          xs={6}
          md={3}
          className="grid__item"
        >
          <div className="content">
            <h4>Ingeniería</h4>{" "}
          </div>
          <Img
            fluid={ingenieriaImageSources}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              zIndex: "-1",
            }}
            alt={""}
          />
        </div>
        <div
          data-sal="flip-up"
          data-sal-delay="600"
          data-sal-easing="ease"
          xs={6}
          md={3}
          className="grid__item"
        >
          <div className="content">
            <h4>Naval</h4>{" "}
          </div>
          <Img
            fluid={navalImageSources}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              zIndex: "-1",
            }}
            alt={""}
          />
        </div>
        <div
          data-sal="flip-up"
          data-sal-delay="500"
          data-sal-easing="ease"
          xs={6}
          md={3}
          className="grid__item"
        >
          <div className="content">
            <h4>Maquinaría Agrícola</h4>{" "}
          </div>
          <Img
            fluid={maquinariaAgricolaImageSources}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              zIndex: "-1",
            }}
            alt={""}
          />
        </div>
        <div
          data-sal="flip-up"
          data-sal-delay="500"
          data-sal-easing="ease"
          xs={6}
          md={3}
          className="grid__item"
        >
          <div className="content">
            <h4>Telecomunicación</h4>{" "}
          </div>
          <Img
            fluid={telecomunicacionesImageSources}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              zIndex: "-1",
            }}
            alt={""}
          />
        </div>
        <div
          data-sal="flip-up"
          data-sal-delay="700"
          data-sal-easing="ease"
          xs={6}
          md={3}
          className="grid__item"
        >
          <div className="content">
            <h4>Instalaciones</h4>{" "}
          </div>
          <Img
            fluid={instalacionesImageSources}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              zIndex: "-1",
            }}
            alt={""}
          />
        </div>
        <div
          data-sal="flip-up"
          data-sal-delay="800"
          data-sal-easing="ease"
          xs={6}
          md={3}
          className="grid__item"
        >
          <div className="content">
            <h4>Petroquímica y agua</h4>{" "}
          </div>
          <Img
            fluid={petroquimicaImageSources}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              zIndex: "-1",
            }}
            alt={""}
          />
        </div>
        <div
          data-sal="flip-up"
          data-sal-delay="500"
          data-sal-easing="ease"
          xs={6}
          md={3}
          className="grid__item"
        >
          <div className="content">
            <h4>Invernaderos</h4>{" "}
          </div>
          <Img
            fluid={invernaderoImageSources}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              zIndex: "-1",
            }}
            alt={""}
          />
        </div>
        <div
          data-sal="flip-up"
          data-sal-delay="500"
          data-sal-easing="ease"
          xs={6}
          md={3}
          className="grid__item"
        >
          <div className="content">
            <h4>Energías Renovables</h4>{" "}
          </div>
          <Img
            fluid={energiaSolarImageSources}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              zIndex: "-1",
            }}
            alt={""}
          />
        </div>
      </div>
    </section>
  )
}

const Sectors = props => (
  <StaticQuery
    query={graphql`
      query {
        construccionImage: file(
          relativePath: { eq: "sectores/construccion.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        automocionImage: file(relativePath: { eq: "sectores/automocion.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        energiaSolarImage: file(
          relativePath: { eq: "sectores/energia_solar.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ingenieriaImage: file(relativePath: { eq: "sectores/ingenieria.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        instalacionesImage: file(
          relativePath: { eq: "sectores/instalaciones.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        invernaderoImage: file(
          relativePath: { eq: "sectores/invernadero.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        maquinariaAgricolaImage: file(
          relativePath: { eq: "sectores/maquinaria_agricola.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        navalImage: file(relativePath: { eq: "sectores/naval.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        petroquimicaImage: file(
          relativePath: { eq: "sectores/petroquimica.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        telecomunicacionesImage: file(
          relativePath: { eq: "sectores/telecomunicaciones.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <SectorsComponent data={data} {...props} />}
  />
)

export default Sectors
