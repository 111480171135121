import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import { Link as LinkScroll } from "react-scroll"

import { Navbar, Nav } from "react-bootstrap"
import Img from "gatsby-image"

const NavbarComponent = ({ data, pageInfo }) => {
  const logoSources = [data.logoImage.childImageSharp.fluid]

  return (
    <Navbar
      data-sal="slide-down"
      data-sal-delay="400"
      data-sal-easing="ease"
      variant="light"
      expand="lg"
      fixed="top"
      id="site-navbar"
    >
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Link to="/" className="link-no-style mx-auto d-lg-none">
        <Navbar.Brand as="span">
          <Img fluid={logoSources} alt={"logo abarconesgonzalez"} />
        </Navbar.Brand>
      </Link>
      <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
        <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
          <LinkScroll spy={true} smooth={true} to="why">
            <Nav.Link as="span">Sobre Nosotros</Nav.Link>
          </LinkScroll>
          <LinkScroll spy={true} smooth={true} to="sectores">
            <Nav.Link as="span">Sectores</Nav.Link>
          </LinkScroll>
        </Nav>
      </Navbar.Collapse>
      <Link to="/" className="link-no-style mx-auto d-none d-lg-block">
        <Navbar.Brand as="span">
          <Img fluid={logoSources} alt={"logo abarconesgonzalez"} />
        </Navbar.Brand>
      </Link>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <LinkScroll spy={true} smooth={true} to="productos">
            <Nav.Link as="span">Productos</Nav.Link>
          </LinkScroll>
          <LinkScroll spy={true} smooth={true} to="contacto">
            <Nav.Link as="span">Contacto</Nav.Link>
          </LinkScroll>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

const NavbarQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        logoImage: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <NavbarComponent data={data} {...props} />}
  />
)

export default NavbarQuery
