import React from "react"

import { Product } from "../../components/sections"
import { StaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import { Row, Col } from "react-bootstrap"

const AbarconesTorneadosComponent = ({ data }) => {
  const abarconesTorneadosImage =
    data.abarconesTorneadosImage.childImageSharp.fluid
  const abarconesTorneados2Image =
    data.abarconesTorneados2Image.childImageSharp.fluid
  const abarconesTorneados3Image =
    data.abarconesTorneados3Image.childImageSharp.fluid
  const abarconesTorneados4Image =
    data.abarconesTorneados4Image.childImageSharp.fluid

  return (
    <Product
      title="Abarcones Torneados"
      name="Abarcones Torneados"
      description="Abarcones con torno para la fijación de tubos de acero al carbono o acero inoxidable en soportes y perfiles, adaptables a cualquier formato. Puede equiparse con las arandelas, tuercas y pletinas correspondientes."
      image={abarconesTorneadosImage}
      metaDescription="Abarcones torneados para la fijación de tubos en soportes y perfiles. Fabricados en Acero al Carbono o Acero Inoxidable. 100% a medida. Calidad certificada."
      gallery={
        <Row id="gallery" className="gallery">
          <Col md={4} className="square">
            <Img fluid={abarconesTorneados2Image} alt={""} />
          </Col>
          <Col md={4} className="square">
            <Img fluid={abarconesTorneados3Image} alt={""} />
          </Col>
          <Col md={4} className="square">
            <Img fluid={abarconesTorneados4Image} alt={""} />
          </Col>
        </Row>
      }
    />
  )
}

const AbarconesTorneados = props => (
  <StaticQuery
    query={graphql`
      query {
        abarconesTorneadosImage: file(
          relativePath: { eq: "products/abarcones_torneados.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesTorneados2Image: file(
          relativePath: { eq: "products/abarcones_torneados_2.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesTorneados3Image: file(
          relativePath: { eq: "products/abarcones_torneados_3.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesTorneados4Image: file(
          relativePath: { eq: "products/abarcones_torneados_4.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <AbarconesTorneadosComponent data={data} {...props} />}
  />
)

export default AbarconesTorneados
