/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent"

import Navbar from "./navBar"
import Footer from "./footer"

const Layout = ({ children, pageInfo, navbar }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        {navbar && <Navbar pageInfo={pageInfo} />}
        <main>{children}</main>
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Vale"
          cookieName="cookies_accept"
          style={{ background: "#066739" }}
          buttonStyle={{
            background: "#fff",
            color: "#066739",
            fontSize: "13px",
          }}
          expires={150}
        >
          Utilizamos cookies para asegurar que damos la mejor experiencia al
          usuario en nuestra web. Si sigues utilizando este sitio asumiremos que
          estás de acuerdo.
        </CookieConsent>
      </>
    )}
  />
)

export default Layout
