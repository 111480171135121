import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Row, Col } from "react-bootstrap"
import { FaArrowLeft } from "react-icons/fa"
import { Link } from "gatsby"

const Privacidad = () => {
  return (
    <Layout navbar={false} pageInfo={{ pageName: "index" }}>
      <SEO description="" title="Aviso Legal" />
      <section className="product--header">
        <Row>
          <Col md={{ span: 5, offset: 1 }} className="justify-content-center">
            <Link to="/">
              <FaArrowLeft />
            </Link>
          </Col>
        </Row>
      </section>
      <section className="product--info">
        <Row>
          <Col md={{ span: 6, offset: 3 }} className="">
            <h2>Protección de datos y Política de Privacidad</h2>
            <p>
              En Abarcones González trabajamos para garantizar la privacidad en
              el tratamiento de sus datos personales. Por ello, hemos revisado
              nuestra Política de Privacidad para informar claramente de cómo
              recogemos, utilizamos y custodiamos los datos de las personas que
              contactan o se relacionan con nosotros:
            </p>
            <ul>
              <li>Responsable del tratamiento</li>
              <li>Finalidades del tratamiento de datos</li>
              <li>Duración del tratamiento de datos</li>
              <li>Legitimación para el tratamiento de datos</li>
              <li>Comunicación de datos</li>
              <li>Derechos de los interesados</li>
              <li>Datos de terceros</li>
            </ul>
            <h4>Responsable del tratamiento:</h4>
            <p>
              El interesado que facilite a Abarcones González datos de carácter
              personal a través de esta web o por otras vías como por ejemplo el
              correo electrónico o las redes sociales queda informado de que el
              tratamiento de sus datos es realizado por:
            </p>
            <p>
              ABARCONES GONZÁLEZ Y DISTRIBUCIONES 2016, S.L.
              <br />
              NIF: B66705880
              <br />
              Domicilio social: Pol. Ind. Santiga C/ Llobateres nº20 Talleres 6
              nave 23, Barberà del Vallès
              <br />
              Teléfono: +34 93 729 90 17
              <br />
              Correo electrónico: info@abarconesgonzalez.com
            </p>
            <h4>Fines del tratamiento de datos:</h4>
            <p>
              El tratamiento de datos se realiza con las siguientes finalidades,
              en función del motivo por el que nos los facilita o le relaciona
              con nosotros:
            </p>
            <ul>
              <li>
                Contactar con el remitente de una información o comunicación,
                dar respuesta a una solicitud, petición o consulta y gestionar
                su seguimiento en su caso.
              </li>
              <li>
                Gestionar, en su caso, un pedido o encargo y / o llevar a cabo
                el servicio contratado por el usuario, así como su facturación y
                cobro.
              </li>
              <li>Informar de nuestros productos y servicios.</li>
            </ul>
            <h4>Duración del tratamiento de datos:</h4>
            <p>
              Los datos para la gestión de la relación con el cliente y la
              facturación y cobro de los servicios, se conservarán durante el
              tiempo de vigencia del contrato, servicio o relación comercial .
              Una vez finalizada la relación, en su caso, los datos podrán ser
              conservados durante el tiempo exigido por la legislación aplicable
              y hasta que prescriban las eventuales responsabilidades derivadas
              de la relación. Los datos para la gestión de consultas y
              solicitudes se conservarán durante el tiempo necesario para dar
              respuesta a las mismas y durante el tiempo posterior necesario
              para su seguimiento y supervisión, en su caso.{" "}
            </p>
            <h4>Legitimación para el tratamiento de datos:</h4>
            <p>
              La base legal para el tratamiento de los datos es la ejecución de
              la prestación del servicio correspondiente. La información de
              productos y servicios tiene como base la satisfacción del interés
              legítimo empresarial consistente en poder ofrecer a los clientes
              la contratación de productos y / o servicios así como conseguir su
              fidelización. Este interés legítimo está reconocido por la
              normativa legal aplicable, el Reglamento General de Protección de
              Datos (RGPD), que permite expresamente el tratamiento sobre dicha
              base legal con fines de mercadotecnia directa. <br />
              No obstante, recordamos a los usuarios que tienen derecho a
              oponerse a este tratamiento de sus datos, pudiendo hacerlo por
              cualquiera de los medios descritos en esta Política de Privacidad.
              La base del envío de comunicaciones e informaciones comerciales
              usuarios no clientes es el consentimiento solicitado, pudiendo ser
              revocado en cualquier momento. La retirada del consentimiento no
              afectará en ningún caso a la ejecución de contratos, pedidos o
              servicios solicitados, y los tratamientos de datos con esta
              finalidad concreta realizados con anterioridad no perderán su
              licitud por el hecho de que el consentimiento se haya revocado .
            </p>
            <h4>Comunicación de datos:</h4>
            <p>
              Los datos serán comunicados a las siguientes personas y entidades:
            </p>
            <ul>
              <li>
                A las entidades financieras a través de las que se realiza la
                gestión de cobros y pagos.
              </li>
              <li>
                A las administraciones públicas competentes, en los casos
                previstos en la Ley y para las finalidades legalmente previstas.
              </li>
              <li>
                En su caso, los colaboradores de Abarcones González con el fin
                de gestionar nuestros productos y servicios cuando sea
                necesario.
              </li>
            </ul>
            <h4>Derechos de los interesados:</h4>
            <p>
              Cualquier persona que nos facilite sus datos tiene los siguientes
              derechos: Obtener confirmación sobre si estamos tratando datos que
              le afectan. Los interesados tienen derecho a acceder a sus datos
              personales, así como solicitar la rectificación de los datos
              inexactos o, en su caso, solicitar su supresión cuando, entre
              otros motivos, los datos ya no sean necesarias para los fines para
              los que fueron recogidos o facilitados. En las condiciones
              previstas en el Reglamento General de Protección de Datos (RGPD),
              podrán solicitar la limitación del tratamiento de sus datos o su
              portabilidad, y en este caso sólo las conservaremos para el
              ejercicio o la defensa de reclamaciones.
              <br />
              En determinadas circunstancias y por motivos relacionados con su
              situación particular, podrán oponerse al tratamiento de sus datos.
              Si han prestado el consentimiento para alguna finalidad
              específica, tienen derecho a retirarlo en cualquier momento, sin
              que ello afecte a la licitud del tratamiento basado en el
              consentimiento previo a su retirada. En estos casos dejaremos de
              tratar los datos o, en su caso, dejaremos de hacerlo para esa
              finalidad en concreto, excepto cuando tengamos motivos legítimos o
              para el ejercicio o la defensa de posibles reclamaciones. Todos
              los derechos mencionados se podrán ejercer dirigiéndose a la
              Dirección de la Empresa a través de los medios de contacto que
              figuran en el apartado de “Responsable del tratamiento” de esta
              Política de Privacidad.
            </p>
            <h4>Datos de terceros:</h4>
            <p>
              Si nos facilite datos de terceros, asuma la responsabilidad de
              informarles previamente de todo lo previsto en el artículo 14 del
              Reglamento General de Protección de Datos (RGPD) en las
              condiciones establecidas en este precepto.
            </p>
          </Col>
        </Row>
      </section>
    </Layout>
  )
}

export default Privacidad
