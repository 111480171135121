import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled, { keyframes } from "styled-components"
import { Row, Col, Button } from "react-bootstrap"
import Img from "gatsby-image"
import { Link as LinkScroll } from "react-scroll"
import ScrollIcon from "../../images/scroll.svg"

const ScrollSvgAnimation = keyframes`
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
`
const ScrollSvgImage = styled(ScrollIcon)`
  width: 30px;
  height: 40px;
  margin-top: 30px;
  animation: ${ScrollSvgAnimation} 4s linear infinite;
`

const HeaderComponent = ({ data }) => {
  const bgImageSources = [
    data.bgImageMobileImage.childImageSharp.fluid,
    {
      ...data.bgImageDesktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <section id="header">
      <Img
        fluid={bgImageSources}
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
        }}
        alt={"abarcones de acero inoxidable"}
      />
      <Row className="vh-100">
        <Col
          data-sal="zoom-in"
          data-sal-delay="700"
          data-sal-easing="ease"
          md={{ span: 6, offset: 3 }}
          lg={{ span: 5, offset: 2 }}
          xl={{ span: 6, offset: 3 }}
          className="d-flex flex-column justify-content-center content text-center"
        >
          <h1>Nuestra fijación, tu solución.</h1>
          <h4>
            Especialistas en Abarcones y sistemas de sujeción a tu medida.{" "}
          </h4>
          <div className="btn__container">
            <LinkScroll smooth={true} to="contacto">
              <Button variant="secondary">Tu Presupuesto</Button>
            </LinkScroll>
            <a title="llámanos" href="tel:937299017">
              <Button variant="primary">Llámanos</Button>
            </a>
          </div>
          <div className="scroll">
            <LinkScroll smooth={true} to="why">
              <ScrollSvgImage />
            </LinkScroll>
          </div>
        </Col>
      </Row>
    </section>
  )
}

const Header = props => (
  <StaticQuery
    query={graphql`
      query {
        bgImageMobileImage: file(relativePath: { eq: "bg/bg2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bgImageDesktopImage: file(relativePath: { eq: "bg/bg2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <HeaderComponent data={data} {...props} />}
  />
)

export default Header
