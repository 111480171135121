// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../../../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-pages-productos-abarcones-cuadrados-js": () => import("./../../../src/pages/productos/abarcones-cuadrados.js" /* webpackChunkName: "component---src-pages-productos-abarcones-cuadrados-js" */),
  "component---src-pages-productos-abarcones-especiales-js": () => import("./../../../src/pages/productos/abarcones-especiales.js" /* webpackChunkName: "component---src-pages-productos-abarcones-especiales-js" */),
  "component---src-pages-productos-abarcones-redondos-js": () => import("./../../../src/pages/productos/abarcones-redondos.js" /* webpackChunkName: "component---src-pages-productos-abarcones-redondos-js" */),
  "component---src-pages-productos-abarcones-sobredimensionados-js": () => import("./../../../src/pages/productos/abarcones-sobredimensionados.js" /* webpackChunkName: "component---src-pages-productos-abarcones-sobredimensionados-js" */),
  "component---src-pages-productos-abarcones-torneados-js": () => import("./../../../src/pages/productos/abarcones-torneados.js" /* webpackChunkName: "component---src-pages-productos-abarcones-torneados-js" */),
  "component---src-pages-productos-abarcones-triangulares-js": () => import("./../../../src/pages/productos/abarcones-triangulares.js" /* webpackChunkName: "component---src-pages-productos-abarcones-triangulares-js" */),
  "component---src-pages-productos-ganchos-y-pernos-js": () => import("./../../../src/pages/productos/ganchos-y-pernos.js" /* webpackChunkName: "component---src-pages-productos-ganchos-y-pernos-js" */),
  "component---src-pages-productos-pletinas-y-tensores-js": () => import("./../../../src/pages/productos/pletinas-y-tensores.js" /* webpackChunkName: "component---src-pages-productos-pletinas-y-tensores-js" */),
  "component---src-pages-productos-varillas-js": () => import("./../../../src/pages/productos/varillas.js" /* webpackChunkName: "component---src-pages-productos-varillas-js" */)
}

