import React from "react"

import { Product } from "../../components/sections"
import { StaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

import { Row, Col } from "react-bootstrap"

const AbarconesEspecialesComponent = ({ data }) => {
  const abarconesEspecialesImage =
    data.abarconesEspecialesImage.childImageSharp.fluid
  const abarconesEspeciales2Image =
    data.abarconesEspeciales2Image.childImageSharp.fluid
  const abarconesEspeciales3Image =
    data.abarconesEspeciales3Image.childImageSharp.fluid
  const abarconesEspeciales4Image =
    data.abarconesEspeciales4Image.childImageSharp.fluid
  const abarconesEspeciales5Image =
    data.abarconesEspeciales5Image.childImageSharp.fluid
  const abarconesEspeciales6Image =
    data.abarconesEspeciales6Image.childImageSharp.fluid
  const abarconesEspeciales7Image =
    data.abarconesEspeciales7Image.childImageSharp.fluid

  return (
    <>
      <Product
        title="Abarcones Especiales"
        name="Abarcones Especiales"
        description="Abarcones personalizados adaptables a cualquier formato y dimensión para la fijación de tubos de acero al carbono o acero inoxidable en soportes y perfiles. Puede equiparse con las arandelas, tuercas y pletinas correspondientes."
        image={abarconesEspecialesImage}
        metaDescription="Abarcones 100% personalizados a cualquier formato y dimensión para la fijación de tubos. Fabricados en Acero al Carbono o Acero Inoxidable. Calidad certificada."
        gallery={
          <Row id="gallery" className="gallery">
            <Col md={4} className="square">
              <Img fluid={abarconesEspeciales2Image} alt={""} />
            </Col>
            <Col md={4} className="square">
              <Img fluid={abarconesEspeciales3Image} alt={""} />
            </Col>
            <Col md={4} className="square">
              <Img fluid={abarconesEspeciales4Image} alt={""} />
            </Col>
            <Col md={4} className="square">
              <Img fluid={abarconesEspeciales5Image} alt={""} />
            </Col>
            <Col md={4} className="square">
              <Img fluid={abarconesEspeciales6Image} alt={""} />
            </Col>
            <Col md={4} className="square">
              <Img fluid={abarconesEspeciales7Image} alt={""} />
            </Col>
          </Row>
        }
      />
    </>
  )
}

const AbarconesEspeciales = props => (
  <StaticQuery
    query={graphql`
      query {
        abarconesEspecialesImage: file(
          relativePath: { eq: "products/abarcones_especiales.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesEspeciales2Image: file(
          relativePath: { eq: "products/abarcones_especiales_2.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesEspeciales3Image: file(
          relativePath: { eq: "products/abarcones_especiales_3.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesEspeciales4Image: file(
          relativePath: { eq: "products/abarcones_especiales_4.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesEspeciales5Image: file(
          relativePath: { eq: "products/abarcones_especiales_5.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesEspeciales6Image: file(
          relativePath: { eq: "products/abarcones_especiales_6.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        abarconesEspeciales7Image: file(
          relativePath: { eq: "products/abarcones_especiales_7.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <AbarconesEspecialesComponent data={data} {...props} />}
  />
)

export default AbarconesEspeciales
