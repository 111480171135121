import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Row, Col, Button } from "react-bootstrap"
import { Link as LinkScroll } from "react-scroll"

import { FaArrowRight } from "react-icons/fa"

import Img from "gatsby-image"

const AboutComponent = ({ data }) => {
  const aboutImageSources = [
    data.aboutMobileImage.childImageSharp.fluid,
    {
      ...data.aboutDesktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <section id="sobre-nosotros">
      <Row>
        <Col
          md={{ span: 5, offset: 1 }}
          className="justify-content-center"
          data-sal="slide-right"
          data-sal-delay="400"
          data-sal-easing="ease"
        >
          <h3>
            Especializados en Abarcones y sistemas de sujeción industrial.
          </h3>
          <p>
            En Abarcones Gónzalez llevamos desde 1978 orientados al
            <strong> servicio al cliente </strong> , apostando por la innovación
            en nuestros procesos de fabricación. Por eso, contamos con la
            flexibilidad para adaptarnos a
            <strong> cualquier tipo de proyecto</strong>, ya sean piezas
            especiales, series cortas o grandes series.
          </p>
          <p>
            Nuestra empresa, fundada y gestionada por José González Moya, se
            mantiene fiel a su lema: "El servicio al cliente ante todo",
            enfocados a ofrecerte la solución y la confianza que tu proyecto
            necesita.
          </p>
          <div>
            <LinkScroll smooth={true} to="contacto">
              <Button variant="outline-primary">
                Te asesoramos <FaArrowRight />
              </Button>
            </LinkScroll>
          </div>
        </Col>
        <Col
          md={{ span: 4, offset: 1 }}
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease"
          className="justify-content-center"
        >
          <Img
            fluid={aboutImageSources}
            alt={"abarconesgonzalez sobre nosotros"}
          />
        </Col>
      </Row>
    </section>
  )
}

const About = props => (
  <StaticQuery
    query={graphql`
      query {
        aboutMobileImage: file(relativePath: { eq: "about.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aboutDesktopImage: file(relativePath: { eq: "about.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <AboutComponent data={data} {...props} />}
  />
)

export default About
