import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Row, Col, Button } from "react-bootstrap"
import { Link as LinkScroll } from "react-scroll"

import Img from "gatsby-image"

const HowComponent = ({ data }) => {
  const howImageSources = [data.howImage.childImageSharp.fluid]
  const how2ImageSources = [data.how2Image.childImageSharp.fluid]

  return (
    <>
      <section id="how">
        <Row>
          <Col
            md={{ span: 5, offset: 1 }}
            className="justify-content-center"
            data-sal="slide-right"
            data-sal-delay="400"
            data-sal-easing="ease"
          >
            <h3>¿Necesitas una fabricación especial?</h3>
            <p>
              Además de fabricar abarcones contemplados en el ramo comercial,
              trabajamos con encargos 100% personalizados y a medida.
              Suminístranos un plano y nos encargarnos de realizar cualquier
              abarcon en todas las medidas comprendidas hasta tubo 40" y
              varillas de M-30.
            </p>
            <p>
              Los materiales que utilizamos normalmente son:
              <br />
              <strong>
                Acero al carbono F-1, F-114, F-125, AISI 304, AISI 316, etc...
              </strong>
            </p>
          </Col>
          <Col
            md={{ span: 4, offset: 1 }}
            data-sal="zoom-in"
            data-sal-delay="300"
            data-sal-easing="ease"
            className="justify-content-center"
          >
            <Img
              fluid={howImageSources}
              alt={"abarconesgonzalez como lo hacemos"}
            />
          </Col>
        </Row>
      </section>
      <section id="how2">
        <Row>
          <Col
            md={{ span: 5, offset: 1 }}
            className="justify-content-center"
            data-sal="slide-right"
            data-sal-delay="400"
            data-sal-easing="ease"
          >
            <p>
              Garantizamos una calidad óptima gracias a nuestro minucioso{" "}
              <strong>control de calidad</strong>, donde aprobamos todos los
              materiales y procesos de fabricación detectando previamente
              cualquier error o defecto en las piezas.
            </p>
            <div>
              <LinkScroll smooth={true} to="contacto">
                <Button variant="outline-primary">¿Tienes un proyecto?</Button>
              </LinkScroll>
            </div>
          </Col>
          <Col
            md={{ span: 4, offset: 1 }}
            data-sal="zoom-in"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <Img
              fluid={how2ImageSources}
              alt={"abarconesgonzalez como lo hacemos 2"}
            />
          </Col>
        </Row>
      </section>
    </>
  )
}

const How = props => (
  <StaticQuery
    query={graphql`
      query {
        howImage: file(relativePath: { eq: "abarcon_medidas.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        how2Image: file(relativePath: { eq: "how.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <HowComponent data={data} {...props} />}
  />
)

export default How
