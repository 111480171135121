import React from "react"

import { Product } from "../../components/sections"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Row, Col } from "react-bootstrap"

const GanchosYPernosComponent = ({ data }) => {
  const ganchosYPernosImage = data.ganchosYPernosImage.childImageSharp.fluid
  const ganchosYPernos2Image = data.ganchosYPernos2Image.childImageSharp.fluid
  const ganchosYPernos3Image = data.ganchosYPernos3Image.childImageSharp.fluid
  const ganchosYPernos4Image = data.ganchosYPernos4Image.childImageSharp.fluid

  return (
    <Product
      title="Ganchos y Pernos"
      name="Ganchos y Pernos"
      description="Ganchos y Pernos fabricados a medida en diferentes formatos y espesores adaptadas a las necesidades y especificaciones de cada proyecto. Ofrecemos soluciones totalmente personalizadas. Puede equiparse con las arandelas de bloqueo, tuercas y pletinas correspondientes."
      image={ganchosYPernosImage}
      metaDescription="Ganchos y Pernos a medida en diferentes formatos y espesores. En Acero Inoxidable o Acero al Carbono. Soluciones 100% personalizadas. Calidad certificada."
      gallery={
        <Row id="gallery" className="gallery">
          <Col md={4} className="square">
            <Img fluid={ganchosYPernos2Image} alt={""} />
          </Col>
          <Col md={4} className="square">
            <Img fluid={ganchosYPernos3Image} alt={""} />
          </Col>
          <Col md={4} className="square">
            <Img fluid={ganchosYPernos4Image} alt={""} />
          </Col>
        </Row>
      }
    />
  )
}

const GanchosYPernos = props => (
  <StaticQuery
    query={graphql`
      query {
        ganchosYPernosImage: file(
          relativePath: { eq: "products/ganchos_y_pernos.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ganchosYPernos2Image: file(
          relativePath: { eq: "products/ganchos_y_pernos_2.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ganchosYPernos3Image: file(
          relativePath: { eq: "products/ganchos_y_pernos_3.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ganchosYPernos4Image: file(
          relativePath: { eq: "products/ganchos_y_pernos_4.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <GanchosYPernosComponent data={data} {...props} />}
  />
)

export default GanchosYPernos
