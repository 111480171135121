import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Header,
  Why,
  About,
  Sectors,
  Products,
  How,
  Contact,
} from "../components/sections"

const IndexPage = () => (
  <Layout navbar={true} pageInfo={{ pageName: "index" }}>
    <SEO title="Especialistas en abarcones y sistemas de sujeción" />
    <Header />
    <Why />
    <About />
    <Sectors />
    <Products />
    <How />
    <Contact />
  </Layout>
)

export default IndexPage
