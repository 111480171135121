import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Row, Col } from "react-bootstrap"
import { FaMagic, FaShieldAlt, FaTools, FaSeedling } from "react-icons/fa"

import Img from "gatsby-image"

const AboutComponent = ({ data }) => {
  const whyImageSources = [
    data.whyMobileImage.childImageSharp.fluid,
    {
      ...data.whyDesktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <section id="why">
      <Row
        className="title"
        data-sal="slide-down"
        data-sal-delay="500"
        data-sal-easing="ease"
      >
        <Col className="text-center" xs={12} md={{ span: 6, offset: 3 }}>
          <h1>¿Por qué elegirnos?</h1>
          <p>
            Nuestra experiencia, nuestra apuesta por la innovación, la calidad y
            nuestro compromiso con el cliente nos avalan.
          </p>
        </Col>
      </Row>
      <Row>
        <Col
          md={4}
          className="justify-content-center"
          data-sal="slide-right"
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          <div className="box">
            <div className="icon">
              <FaTools />
            </div>
            <h4>Expertos en Abarcones</h4>
            <p>Con más de 40 años de experiencia en el sector.</p>
          </div>
          <div className="box">
            <div className="icon">
              <FaSeedling />
            </div>
            <h4>Innovación</h4>
            <p>
              Nuestra tecnología evoluciona con nosotros para ofrecer las
              mejores soluciones.
            </p>
          </div>
        </Col>
        <Col
          md={4}
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease"
          className="d-none d-md-block justify-content-center"
        >
          <Img fluid={whyImageSources} alt={"abarcones de acero inoxidable"} />
        </Col>
        <Col
          md={4}
          className="justify-content-center"
          data-sal="slide-left"
          data-sal-delay="400"
          data-sal-easing="ease"
        >
          <div className="box">
            <div className="icon">
              <FaShieldAlt />
            </div>
            <h4>Calidad y compromiso</h4>
            <p>
              Profesionales orientados a la solución que necesitas, con los
              mejores acabados.
            </p>
          </div>
          <div className="box">
            <div className="icon">
              <FaMagic />
            </div>
            <h4>Servicio y flexibilidad</h4>
            <p>
              Nos adaptamos a tu proyecto y a tus necesidades. 100%
              personalizado.
            </p>
          </div>
        </Col>
      </Row>
    </section>
  )
}

const About = props => (
  <StaticQuery
    query={graphql`
      query {
        whyMobileImage: file(relativePath: { eq: "why.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        whyDesktopImage: file(relativePath: { eq: "why.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <AboutComponent data={data} {...props} />}
  />
)

export default About
